define([], () => {
  class AccessibilityFocusHelper {

    constructor() {
      this.focusableElements = 'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';
    }

    enableElement(element) {
      if (!element) return;
      element.setAttribute('tabindex', '0');
      element.removeAttribute('aria-hidden');
    }

    disableElement(element) {
      if (!element) return;
      element.setAttribute('tabindex', '-1');
      element.setAttribute('aria-hidden', 'true');
    }

    enableElements(elements) {
      elements && Array.prototype.slice.call(elements).forEach(element => this.enableElement(element));
    }

    disableElements(elements) {
      elements && Array.prototype.slice.call(elements).forEach(element => this.disableElement(element));
    }

    filterFocusable(elements) {
      return elements && Array.prototype.slice.call(elements).filter(element => this.isFocusable(element));
    }

    isFocusable(element) {
      const focusableTagNames = ['A', 'BUTTON', 'INPUT', 'SELECT', 'TEXTAREA'];
      return !!element && (focusableTagNames.includes(element.tagName) || element.getAttribute('tabIndex') === '0');
    }

    focus(element) {
      if (!element) return;
      if (!this.isFocusable(element)) {
        element.setAttribute('tabindex', '-1');
        element.addEventListener('blur', () => {
          element.removeAttribute('tabindex');
        });
      }
      element.focus();
    }
    
    focusAndScroll(element) {
      this.focus(element);
      element.scrollIntoView();
      
      const westendHeader = document.querySelector('.westendHeader');
      westendHeader && window.scrollBy(0, -westendHeader.clientHeight);
    }
  }

  return new AccessibilityFocusHelper();
});
